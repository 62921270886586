export const getViewportBreakpoint = () => {
  var w = window.innerWidth;
  if (w) {
    return w < 480
      ? 'xs'
      : w < 768
      ? 's'
      : w < 912
      ? 'm'
      : w < 1152
      ? 'l'
      : w < 1440
      ? 'xl'
      : 'xxl';
  } else {
    return 'xs';
  }
};

export const getDisplayedBrand = (telefonverkauf: boolean, theme: string | null) => {
  return telefonverkauf
    ? 'DKVTelefonie'
    : theme === 'ergo-one' || theme === 'ergo'
    ? 'ERGO'
    : 'DKV';
};

export type ITrackingPage = {
  conversionType: string | null;
  pageName: string;
};

export type ITrackingPageMap = Record<string, ITrackingPage>;

export type ITrackingKategorie = {
  adobeKategorie: string;
  adobeProduktgruppe: string;
  productBranch: string;
  productOwner: string;
};

export type ITrackingKategorieMap = Record<string, ITrackingKategorie>;

export const getTrackingKategorie = (omnitureKategorie: string): ITrackingKategorie => {
  const adobeTrackingParams: ITrackingKategorieMap = {
    zez: {
      adobeKategorie: 'zez', // Product (eVar3)
      adobeProduktgruppe: 'ZahnZusatz', // Productgroup (eVar1)
      productBranch: 'Kranken', // ProductBranch (hier2)
      productOwner: 'ERGO',
    },
    zahn: {
      adobeKategorie: 'zahn',
      adobeProduktgruppe: 'ZahnZusatz',
      productBranch: 'Kranken',
      productOwner: 'ERGO',
    },
    schutzbrief: {
      adobeKategorie: 'kfzsb',
      adobeProduktgruppe: 'Mobil',
      productBranch: 'Komposit',
      productOwner: 'ERGO',
    },
    pkv: {
      adobeKategorie: 'PrivateKranken',
      adobeProduktgruppe: 'pkv',
      productBranch: 'Kranken',
      productOwner: 'DKV',
    },
    zuhauseversichern: {
      adobeKategorie: 'HausratGebaeude',
      adobeProduktgruppe: 'HausratGebaeude',
      productBranch: 'Komposit',
      productOwner: 'ERGO',
    },
    zahnzusatz: {
      adobeKategorie: 'kvzzahn',
      adobeProduktgruppe: 'Zahnzsatzversicherung',
      productBranch: 'Kranken',
      productOwner: 'DKV',
    },
    rechtsschutz: {
      adobeKategorie: 'Rechtsschutz',
      adobeProduktgruppe: 'Rechtsschutz',
      productBranch: 'Komposit',
      productOwner: 'ERGO',
    },
    dentalschutz: {
      adobeKategorie: 'ZahnZusatz',
      adobeProduktgruppe: 'ZahnZusatz',
      productBranch: 'Kranken',
      productOwner: 'ERGODIREKT',
    },
    hausrat: {
      adobeKategorie: 'HausratGebaeude',
      adobeProduktgruppe: 'HausratGebaeude',
      productBranch: 'Komposit',
      productOwner: 'ERGO',
    },
    autoversicherung: {
      adobeKategorie: 'kfz',
      adobeProduktgruppe: 'Mobil',
      productBranch: 'Komposit',
      productOwner: 'ERGO',
    },
    privatkunden: {
      adobeKategorie: 'AlleProdukte',
      adobeProduktgruppe: 'AlleProdukte',
      productBranch: 'Sonstiges',
      productOwner: 'ERGO',
    },
    starkesteam: {
      adobeKategorie: 'AlleProdukte',
      adobeProduktgruppe: 'AlleProdukte',
      productBranch: 'Sonstiges',
      productOwner: 'ERGO',
    },
    risikolv: {
      adobeKategorie: 'risikolv',
      adobeProduktgruppe: 'RisikoLeben',
      productBranch: 'Leben',
      productOwner: 'ERGO',
    },
    investment: {
      adobeKategorie: 'investment',
      adobeProduktgruppe: 'Geldanlage',
      productBranch: 'Sonstiges',
      productOwner: 'ERGO',
    },
    finanzplanung: {
      adobeKategorie: 'investf',
      adobeProduktgruppe: 'Geldanlage',
      productBranch: 'Sonstiges',
      productOwner: 'ERGO',
    },
    krankenhaus: {
      adobeKategorie: 'kvzstationaer',
      adobeProduktgruppe: 'StationaereZusatzversicherung',
      productBranch: 'Kranken',
      productOwner: 'DKV',
    },
    zuhauseabsichern: {
      adobeKategorie: 'HausratGebaeudeImmowelt',
      adobeProduktgruppe: 'HausratGebaeude',
      productBranch: 'Komposit',
      productOwner: 'ERGO',
    },
    reiseversicherung: {
      adobeKategorie: 'Reise',
      adobeProduktgruppe: 'Reise',
      productBranch: 'Kranken',
      productOwner: 'ERGO',
    },
    unfallversicherung: {
      adobeKategorie: 'upk',
      adobeProduktgruppe: 'Unfallversicherung',
      productBranch: 'Komposit',
      productOwner: 'ERGO',
    },
    gewerbekunden: {
      adobeKategorie: 'Gewerbe',
      adobeProduktgruppe: 'Gewerbe',
      productBranch: 'Komposit',
      productOwner: 'ERGO',
    },
    ebike: {
      adobeKategorie: 'ebike',
      adobeProduktgruppe: 'HausratGebaeude',
      productBranch: 'Komposit',
      productOwner: 'ERGO',
    },
    checkcare: {
      adobeKategorie: 'KrankenZusatz',
      adobeProduktgruppe: 'KrankenZusatz',
      productBranch: 'Kranken',
      productOwner: 'ERGO',
    },
    danv: {
      adobeKategorie: 'Gewerbe',
      adobeProduktgruppe: 'Gewerbe',
      productBranch: 'Komposit',
      productOwner: 'ERGO',
    },
    dfbfrauen: {
      adobeKategorie: 'AlleProdukte',
      adobeProduktgruppe: 'AlleProdukte',
      productBranch: 'Sonstiges',
      productOwner: 'ERGO',
    },
    baufinanzierung: {
      adobeKategorie: 'immof',
      adobeProduktgruppe: 'Baufinanzierung',
      productBranch: 'Sonstiges',
      productOwner: 'ERGO',
    },
    wohngebaeude: {
      adobeKategorie: 'wg',
      adobeProduktgruppe: 'HausratGebaeude',
      productBranch: 'Komposit',
      productOwner: 'ERGO',
    },
    bu: {
      adobeKategorie: 'bu',
      adobeProduktgruppe: 'Einkommen',
      productBranch: 'Leben',
      productOwner: 'ERGO',
    },
    beamten: {
      adobeKategorie: 'pkv_b',
      adobeProduktgruppe: 'PrivateKranken',
      productBranch: 'Kranken',
      productOwner: 'ERGO',
    },
    altersvorsorge: {
      adobeKategorie: 'Private Rentenversicherung',
      adobeProduktgruppe: 'Ruhestand',
      productBranch: 'Sonstiges',
      productOwner: 'ERGO',
    },
    motorradversicherung: {
      adobeKategorie: 'krad', // Product (eVar3)
      adobeProduktgruppe: 'Mobil', // Productgroup (eVar1)
      productBranch: 'Komposit', // ProductBranch (hier2)
      productOwner: 'ERGO',
    },
    sterbegeld: {
      adobeKategorie: 'sterbe', // Product (eVar3)
      adobeProduktgruppe: 'Sterbegeld', // Productgroup (eVar1)
      productBranch: 'Leben', // ProductBranch (hier2)
      productOwner: 'ERGO',
    },
  };

  return adobeTrackingParams[omnitureKategorie];
};
